import React, { Component } from 'react'

import Loader from 'Components/Loader'
import {
  AlertText,
  AlertWrapper,
  TillNumber,
  TillNumberWrapper,
  TillNumberWrapperAbsolute,
} from 'Common/styled'

import api from 'Common/api'
import { getQueryParam, handleServerError } from 'Common/utils'

class App extends Component {
  state = {
    isLoading: false,
  }
  componentDidMount () {
    this.getConfig()
  }

  getConfig () {
    const displayId = getQueryParam('displayId')
    this.setState({ isLoading: true })
    api.display
      .getConfig(displayId)
      .then(res => {
        const { data, status } = res
        if (status === 204) return this.setState({ isLoading: false })
        return this.setState({ isLoading: false, ...data.config }, () =>
          this.getAlert()
        )
      })
      .catch(err => handleServerError(err))
  }

  getAlert () {
    const { storeId, queueId } = this.state
    this.startTimer(300)
    api.display
      .alert(storeId, queueId)
      .then(res => {
        const { data, status } = res
        const till = status === 202 ? undefined : data.call.till
        this.startTimer(data.timer)
        this.setState({ till, tillColor: data.color, imgRefresh: Date.now() })
        if (status === 200) document.getElementById('till-sound').play()
      })
      .catch(err => {
        console.error(err)
        this.startTimer(15)
      })
  }

  startTimer (timer) {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => this.getAlert(), timer * 1000)
  }

  renderTillAlert () {
    const { type, till, tillColor } = this.state
    if (type === 'statusQueue') {
      if (till) {
        return (
          <AlertWrapper>
            <AlertText>Cassa</AlertText>
            <TillNumberWrapper>
              <TillNumber>{till}</TillNumber>
            </TillNumberWrapper>
            <AlertText>libera</AlertText>
          </AlertWrapper>
        )
      }
    } else {
      return (
        <TillNumberWrapperAbsolute color={tillColor}>
          <TillNumber>{till}</TillNumber>
        </TillNumberWrapperAbsolute>
      )
    }
  }

  render () {
    const { isLoading, displayId, till, imgRefresh } = this.state
    if (isLoading) {
      return <Loader />
    }
    return (
      <>
        {displayId && this.renderTillAlert()}
        <img
          src={`${process.env.REACT_APP_IMG_API_URL}/display/image?displayId=${displayId}&${imgRefresh}`}
          alt="display"
        />
        <video id="till-sound" src={`/assets/mp3/tills/${till}.mp4`} hidden />
      </>
    )
  }
}
export default App
